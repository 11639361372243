<template>
  <div @click="gotToStoreDetails(storeItem)">
    <div class="store-card rounded cursor-pointer">
      <section
          :style="{
        'background-size': 'cover',
        'background-position': 'center',
        'background-image':`url(${storeItem.cover})`,
      }"
          class="card-banner position-relative w-100 h-160px"
      ></section>
      <div class="storeInfo bg-white p-3 position-relative min-h-150">
        <div class="position-absolute logoStoreCard">
          <div class="logoCard bg-white shadow rounded-circle">
            <img
                class="img-fluid h-100"
                :src= 'storeItem.logo'
                :alt="storeItem.name"
            />
          </div>
        </div>
        <div class="storeContentInfo text-center">
          <!--        <div class="star-icons d-flex justify-content-center">-->
          <!--          <img-->
          <!--            class="inline-flex mr-1"-->
          <!--            :src="require('@/assets/images/ibbil/star.svg')"-->
          <!--          />-->
          <!--          <img-->
          <!--            class="inline-flex mr-1"-->
          <!--            :src="require('@/assets/images/ibbil/star.svg')"-->
          <!--          />-->
          <!--          <img-->
          <!--            class="inline-flex mr-1"-->
          <!--            :src="require('@/assets/images/ibbil/star.svg')"-->
          <!--          />-->
          <!--          <img-->
          <!--            class="inline-flex mr-1"-->
          <!--            :src="require('@/assets/images/ibbil/star.svg')"-->
          <!--          />-->
          <!--          <img-->
          <!--            class="inline-flex mr-1"-->
          <!--            :src="require('@/assets/images/ibbil/star.svg')"-->
          <!--          />-->
          <!--        </div>-->
          <h5 class="name-of-store font-weight-bold">{{storeItem.name.length >= 25 ? storeItem.name.slice(0, 25)+'....' : storeItem.name}}</h5>
          <div class="hovered-content pb-2 position-absolute w-100">
            <div class="container">
              <p class="text-muted font-size-14">{{storeItem.description.length >= 70 ? storeItem.description.slice(0, 70)+'....'  : storeItem.description}}</p>
<!--              <p class="text-muted">{{storeItem.description.length > 0  storeItem.description.slice(0,10)}}</p>-->
              <div
                  class="action-btns text-muted d-flex align-items-center justify-content-between"
              >
                <div class="action d-flex align-items-center">
                  <span class="count-number">{{ storeItem.total_reviews }}</span>
                  <i class="lar la-comment"></i>
                </div>
                <div class="action d-flex align-items-center">
                  <span class="count-number">{{ storeItem.total_views }}</span>
                  <i class="las la-eye"></i>
                </div>
                <div class="action d-flex align-items-center">
                  <span class="count-number">{{storeItem.total_favourites }}</span>
                  <i class="las la-thumbs-up"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { core } from '@/config/pluginInit'
export default {
  name: 'storesGrid',
  props: ['storeItem'],
  data () {
    return {
      defaultComment: 5,
      defaultViews: 12,
      defaultLikes: 8,
      description: 'لا يوجد وصف لهاذا المتجر '
    }
  },
  methods: {
    gotToStoreDetails (payload) {
      this.$store.commit('cart/changeSelectedStore', payload)
      this.$router.push({ name: 'storeProfile', params: { id: this.$route.params.id, slogan: payload.url } })
    }
  },
  mounted () {
    core.index()
  }
}
</script>

<style lang="scss">
.hovered-content .container p{
  min-height: 70px
}
.W-80{
  width: 80% !important;
}
.min-h-150 {
  min-height: 160px !important;
}
.name-of-store {
  padding: 5px 0;
  font-size: 16px;
}
@mixin hoverEffect {
  .logoStoreCard {
    right: 10px;
    transform: translateX(6px);
    .logoCard {
      transform: scale(0.7);
    }
  }
  .storeContentInfo {
    padding-top: 0px;
    .star-icons {
      /*transform: translate(2px, -40px);*/
      transform: translate(15px, -26px)
    }
    h5 {
      color: var(--iq-primary);
    }
    .hovered-content {
      // background: #fff;
      left:0;
      height: initial;
      opacity: 1;
      visibility: visible;
    }
  }
}
.store-card {
  border-radius: 10px ;
  overflow: hidden;
  .logoStoreCard {
    top: -50px;
    right: 50%;
    transform: translateX(50%);
    transition: 0.3s ease-in-out;
  }
  .logoCard {
    width: 95px;
    height: 95px;
    overflow: hidden;
    transition: 0.3s ease-in-out;
  }
  .storeContentInfo {
    padding-top: 50px;
    transition: 0.3s ease-in-out;
    .star-icons {
      transition: 0.3s ease-in-out;
    }
    .hovered-content {
      // position: absolute;
      // width: 100%;
      // border-radius: 0 0 10px 10px;
      // left: 0;
      height: 0;
      opacity: 0;
      visibility: hidden;
      transition: 0.3s ease-in-out;
      .action-btns {
        .action {
          gap: 5px;
          i {
            font-size: 24px;
          }
          span {
            font-size: 18px;
          }
        }
      }
    }
  }
  .card-banner {
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba($color: #000000, $alpha: 0.5);
      transition: 0.3s ease-in-out;
    }
  }
  &:hover {
    .name-of-store {
      padding: 5px;
      transition:  0.3s ease-in-out;
    }
    .storeInfo {
      padding-top: 0 !important;
      transition:  0.3s ease-in-out;
    }
    .storeInfo h5 {
      margin-right: 10px;
    }
    .card-banner {
      &::before {
        background: rgba($color: #000000, $alpha: 0.3);
      }
    }
    @include hoverEffect;
  }
  @media (max-width: 992px) {
    @include hoverEffect;
    .storeContentInfo {
      .hovered-content {
        position: relative;
      }
    }
  }
}

</style>
